.payment-loader-back {
  border-radius: 20px;
  border: 1px solid var(--Subcolor, #dedede);
  background: var(--Module, #fff);
  box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.05);
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  width: 580px;
  padding: 60px 89px 60px 89px;
}

.checkbox-list-con {
  border-radius: 15px;
  border: 1px solid var(--Subcolor, #dedede);
  display: flex;
  padding: 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 402px;
}

.loader-payment {
  display: flex;
  align-items: center;
}

.loader-label {
  color: var(--module-dark, #1b1d21);
  font-family: "Inter";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.loader {
  width: 50px;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 8px solid lightblue;
  border-right-color: orange;
  animation: l2 1s infinite linear;
}

.loader-continous {
  width: 62px;
  padding: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #ff7228;
  --_m: conic-gradient(#0000 30%, #000), linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
  mask: var(--_m);
  -webkit-mask-composite: source-out;
  mask-composite: subtract;
  animation: l3 1s infinite linear;
}

@keyframes l3 {
  to {
    transform: rotate(1turn);
  }
}
