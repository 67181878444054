@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

:root {
  --primary-color: #ff7228;
  --primary-color-opacity-0_15: rgba(255, 114, 40, 0.15);
  --normal-grey: #86898e;
  --light-grey: #f4f5f6;
  --white-mode-maintext-dark: #1b1d21;
  --border-color-one: #dedede;
  --MainText: #ffffff;
  --white: #ffffff;
  --black: #000000;
  --dark-light: #292d34;
  --error-text-color: #ff4444;
  --error-BgColor-opacity-0_15: rgba(255, 111, 111, 0.15);
  --inter-font-family: "Inter", sans-serif;
  --polySansBulky-font-family: "PolySans Bulky";
  --polySans-font-family: "PolySans Median";
  --polySans-neutral-font-family: "PolySans neutral";
  --green: #34c57f;
  --yellow: #ffb51c;
  --danger: #ff3a28;
  --placeholdergray: #d3d3d4;
  --dark-brown-color: #352218;
}

@font-face {
  font-family: "PolySans Bulky";
  src: url("../src/assets/fonts/5645b7453bd2df2bf9eb77e589a4afd8.eot");
  src: url("../src/assets/fonts/5645b7453bd2df2bf9eb77e589a4afd8.eot?#iefix")
      format("embedded-opentype"),
    url("../src/assets/fonts/5645b7453bd2df2bf9eb77e589a4afd8.woff")
      format("woff"),
    url("../src/assets/fonts/5645b7453bd2df2bf9eb77e589a4afd8.woff2")
      format("woff2"),
    url("../src/assets/fonts/5645b7453bd2df2bf9eb77e589a4afd8.ttf")
      format("truetype"),
    url("../src/assets/fonts/5645b7453bd2df2bf9eb77e589a4afd8.svg#PolySans Bulky")
      format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "PolySans Median";
  src: url("../src/assets/fonts/PolySans-Median.woff2") format("woff2"),
    url("../src/assets/fonts/PolySans-Median.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Neue Montreal";
  src: url("../src/assets/fonts/NeueMontreal-Medium.woff2") format("woff2"),
    url("../src/assets/fonts/NeueMontreal-Medium.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
  -webkit-font-smoothing: auto !important;
  font-display: swap;
}

@font-face {
  font-family: "PolySans bulky";
  src: url("../src/assets/fonts/PolySans-Bulky.woff") format("woff"),
    url("../src/assets/fonts/PolySans-Bulky.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "PolySans median";
  src: url("../src/assets/fonts/PolySans-Median.woff") format("woff"),
    url("../src/assets/fonts/PolySans-Median.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

html,
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
a,
ul,
li,
b,
strong,
section,
div,
ol,
header,
input,
textarea,
form,
button,
table,
td,
tr,
th,
footer {
  font-family: "Inter";
}

@supports (font-variation-settings: normal) {
  :root {
    font-family: "Inter";
    -webkit-font-smoothing: auto !important;
  }
}

.App {
  background-color: var(--white);
  min-height: 100vh;
  position: relative;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.ant-picker-dropdown {
  z-index: 99999;
}

.signup-flow {
  overflow: hidden;
}

@media screen and (min-width: 1400px) {
  .container {
    max-width: 1274px;
  }
}

.subcribe-form input::placeholder {
  color: var(--placeholdergray) !important;
}

* {
  box-sizing: border-box;
}
