.app-bar-menu .MuiPopover-paper {
  margin-top: 69px;
  border-radius: 15px;
  border: 1px solid var(--Subcolor, #dedede);
  background: var(--Module, #fff);
  box-shadow: 0px 15px 15px 0px rgba(0, 0, 0, 0.02);
}

.app-bar-menu .Mui-focusVisible {
  background-color: transparent !important;
}

.app-bar-menu li:not(:last-child) {
  border: none;
  border-bottom: 1px solid #f4f5f6;
}
.app-bar-menu li {
  height: 44px;
  background: transparent !important;
}
.app-bar-menu ul {
  margin: 0 10px;
}

@media screen and (max-width: 768px) {
  .app-bar-menu .MuiPopover-paper {
    margin-top: 45px;
  }
  .app-bar-menu li {
    padding: 0 !important;
    background: transparent !important;
  }
  .icon-style-menu {
    height: 18px;
    width: 22px;
  }
}
